.page-header {
  .carousel .carousel-indicators {
    bottom: 60px;
  }

  .container {
    color: $white;
  }

  .title {
    color: $white;
  }

  &.header-small {
    height: 65vh;
    min-height: 65vh;

    .container {
      padding-top: 20vh;
    }
  }
}

.landing-page {
  .content-center {
    img {
      height: 300px;
    }
  }
}

.header-filter {
  position: relative;
  // background: black;
  background: #f5f5dc;

  .container {
    z-index: 2;
    // position: relative;

    @media screen and (max-width: 768px) {
      &.container-mobile {
        .brand.brand-photo {
          top: 20% !important;
          left: 75% !important;
          width: -moz-max-content;
          width: max-content;
          zoom: 250%;

          .my-image {
            height: auto;
            width: fit-content !important;
            width: -moz-fit-content !important;
            width: fit-content !important;
          }
          .notfoundimage {
            height: 450px;
            width: auto !important;
            right: 10% !important;
            top: 16% !important;
          }
        }
        .brand.brand-name {
          top: 55% !important;
          left: 50% !important;
          text-align: start;
          width: 100%;
          font-size: 8px;
          padding: 0 30px;
          
          .header-intro {
            font-size: 60px;
            line-height: 60px;
          }
          h1, .quote {
            color: white !important;
            padding: 10px;
          }
          h1 {
            background: #3a3a3a2b;
          }
          .quote {
            font-size: 32px !important;
          }
          .slider-front {
            width: 180px !important;
          }
        }
      }
    }

  }
}
.clear-filter {
  &:before {
    display: none;
  }
}

.iframe-container iframe {
  width: 100%;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.3);
}

.header-1,
.header-2,
.header-3 {
  .wrapper {
    background: #cccccc;
  }
  .content-center {
    max-width: none !important;
  }
}

.header-2,
.header-1 {
  .page-header {
    .card {
      margin-top: 60px;
    }
  }
}

.header-3 {
  .btn {
    margin: 0;
  }
  h6 {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .page-header {
    .content-center {
      .row {
        text-align: center !important;
      }
      img {
        height: 150px;
        margin-top: 40px;
      }
    }
  }
}
.wrapper .navbar {
  z-index: 2;
}
