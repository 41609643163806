// fonts

@font-face {
  font-family: ledPanel;
  src: url(/../../../assets/font/ledPanel.ttf) format("opentype");

  @font-face {
    font-family: 'ledPanel';
    src: url('/assets/font/ledPanel.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

  // font-family: GraublauWeb;
  //   src: url("path/GraublauWeb.otf") format("opentype");

  // font-family: 'ledPanel';
  // src:  url('/../../../assets/font/ledPanel.eot?5dqoim');
  // src:  url('/../../../assets/font/ledPanel.eot?5dqoim#iefix') format('embedded-opentype'),
  //   url('/../../../assets/font/ledPanel.ttf?5dqoim') format('truetype'),
  //   url('/../../../assets/font/ledPanel.woff?5dqoim') format('woff'),
  //   url('/../../../assets/font/ledPanel.svg?5dqoim#ledPanel') format('svg');
  // font-weight: normal;
  // font-style: normal;
}


// style for the landing page
.index-page {
  .page-header {
    height: 100vh;

    max-width: unset;
    background-image: linear-gradient(#f5f5dc 2px, transparent 2px), linear-gradient(90deg, #f5f5dc 2px, transparent 2px), linear-gradient(rgba(150, 150, 150, 0.3) 1px, transparent 1px), linear-gradient(90deg, rgba(150, 150, 150, 0.3) 1px, transparent 1px);
    background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
    background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
    background-attachment: fixed;
    background-position: center;

    

    .container {
      > .content-center {
        &.brand {
          .h1-seo,
          h3 {
            color: $font-primary;
            font-weight: 600;
            text-transform: capitalize;
            span {
              font-weight: $font-weight-bold;
            }
          }
        }
      }
    }

    .category-absolute {
      position: absolute;
      top: 100vh;
      margin-top: -60px;
      padding: 0 15px;
      width: 100%;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .squares {
    animation: move-left-right 8s infinite;
    @include linear-gradient($primary-states, $primary);
    position: absolute;
    transition: 0.5s ease-out;
    overflow: hidden;
    border-radius: 20%;
    &.square1 {
      animation: move-left-right 7s infinite;
      // background-image: url("/assets/img/square5.png");
      background: linear-gradient(0deg, #148031 0%, #9a8b1a 100%);
      // background: linear-gradient(0deg, #eb7474 0%, #d7c751 100%);
      -webkit-filter: blur(100px);
      filter:  blur(100px);
      height: 500px;
      width: 500px;
      left: 3%;
      top: -21%;
    }

    &.square2 {
      animation: move-up-down 8s infinite;
      background: linear-gradient(0deg, #33660b 0%, #085684 100%);
      // background: linear-gradient(0deg, #5d8b3a 0%, #43d5c6 100%);
      -webkit-filter: blur(100px);
      filter:  blur(100px);
      height: 500px;
      width: 500px;
      right: -5%;
      top: -12%;
    }

    &.square3 {
      animation: move-left-right 6s infinite;
      background: linear-gradient(0deg, #1b6908 0%, #b17f00 100%);
      // background: linear-gradient(0deg, #972334 0%, #f0c049 100%);
      -webkit-filter: blur(100px);
      filter:  blur(100px);
      height: 500px;
      width: 500px;
      left: -5%;
      bottom: 0%;
    }

    &.square4 {
      animation: move-up-down 8s infinite;
      background: linear-gradient(0deg, #048d22 0%, #088ca0 100%);
      // background: linear-gradient(0deg, #6779d2 0%, #660b6b 100%);
      -webkit-filter: blur(100px);
      filter:  blur(100px);
      height: 500px;
      width: 500px;
      right: 27%;
      top: 70%;
    }

    &.square5 {
      animation: move-left-right 10s infinite;
      // background-image: url("/assets/img/square15.png");
      background: linear-gradient(0deg, #2f32dd 0%, #034e03 100%);
      // background: linear-gradient(0deg, #ff0000 0%, #3118d5 100%);
      -webkit-filter: blur(100px);
      filter:  blur(100px);
      height: 500px;
      width: 500px;
      // opacity: 0.1;
      left: 32%;
      bottom: 29%;

    }

    &.square6 {
      animation: move-up-down 8s infinite;
      background: linear-gradient(0deg, #015410 0%, #0467a9 100%);
      // background: linear-gradient(0deg, #419b51 0%, #8aa62f 100%);
      -webkit-filter: blur(100px);
      filter:  blur(100px);
      left: 10%;
      top: 35%;
      height: 500px;
      width: 500px;
    }

    &.square7 {
      animation: move-left-right 6s infinite;
      background: linear-gradient(0deg, #004c12 0%, #2720af 100%);
      // background: linear-gradient(0deg, #77de8f 0%, #ed4770 100%);
      -webkit-filter: blur(100px);
      filter:  blur(100px);
      height: 500px;
      width: 500px;
      right: -5%;
      bottom: 0%; 
    }
  }
}
.landing-page {
  overflow-x: hidden;

  .page-header {
    &::after {
      background: radial-gradient(
        ellipse at top right,
        #292d61 23%,
        #171941 65%
      );
      // content: "";
      position: absolute;
      height: 100vh;
      width: 100%;
      opacity: 0.5;
    }
    .path {
      max-width: 65%;
      right: 0;
      top: 30px;
    }

    .path2 {
      position: absolute;
      opacity: 0.02;
      max-width: 41%;
      right: -60px;
      top: -50px;
    }

    .squares {
      top: 50%;
      right: 18%;
      max-height: 200px;
    }

    .wave {
      top: 45%;
      right: 30%;
      max-height: 90px;
    }

    .circle {
      top: 20%;
      right: 36%;
      max-height: 75px;
    }

    .triangle {
      top: 35%;
      left: 5%;
      max-height: 200px;
    }
  }

  .section:first-of-type {
    .path {
      left: -110px;
      max-width: 60%;
      top: -50px;
    }
  }
  .section:nth-of-type(2) {
    .path {
      left: auto;
      right: -250px;
      max-width: 70%;
      top: 0;
    }
    .path2 {
      position: absolute;
      opacity: 0.02;
      right: auto;
      left: 420px;
      max-width: 10%;
      top: 420px;
    }

    .path3 {
      position: absolute;
      opacity: 0.02;
      left: auto;
      right: 500px;
      max-width: 10%;
      top: -90px;
    }
  }
  .section:nth-of-type(3) {
    padding-top: 200px;

    .path {
      right: auto;
      left: 50px;
      max-width: 45%;
      top: 60px;
    }
  }
  .section:nth-of-type(4) {
    .path {
      max-width: 60%;
      right: 3%;
      top: 15%;
    }
    .path2 {
      position: absolute;
      opacity: 0.02;
      left: 25%;
      right: auto;
      max-width: 10%;
      top: 100px;
    }
  }
  .section:nth-of-type(5) {
    .path {
      max-width: 50%;
      left: 5%;
      top: 3%;
    }
  }

  .header {
    height: 100vh;
    position: relative;

    .container {
      padding-top: 26vh;
      color: $white;
      z-index: 2;
      position: relative;
    }

    .share {
      margin-top: 150px;
    }
    h1 {
      font-weight: 600;
    }
    .title {
      color: $white;
    }
  }

  .section-team {
    .team .team-player img {
      max-width: 100px;
    }

    .team-player {
      margin-bottom: 15px;
    }
  }

  .section-contact-us {
    .title {
      margin-bottom: 15px;
    }

    .description {
      margin-bottom: 30px;
    }

    .input-group,
    .send-button,
    .textarea-container {
      padding: 0 40px;
    }

    .textarea-container {
      margin: 40px 0;
    }

    a.btn {
      margin-top: 35px;
    }
  }

  .section-safe {
    .icon {
      i {
        font-size: 21px;
      }
    }
  }
}

// style for the profile page
.profile-page {
  overflow-x: hidden;

  .page-header {
    min-height: 550px;

    .container {
      padding-top: 30vh;
    }

    .path {
      max-width: 65%;
      right: 0;
      top: 100px;
    }
  }

  .info {
    padding: 40px 0 0;

    .description {
      text-align: left;
      padding-left: 20px;
    }
  }

  .profile-title {
    position: absolute;
    top: 110px;
    left: 60px;
  }

  .profile-description {
    margin-top: -30px;
    max-width: 450px;
    font-size: $font-size-base;
    line-height: 26px;
  }

  .profile-container {
    color: $white;
  }
  .photo-container {
    max-width: 400px;
    overflow: hidden;
    -webkit-box-shadow: -15px 20px 60px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: -15px 20px 60px 0px rgba(0, 0, 0, 0.3);
    box-shadow: -15px 20px 60px 0px rgba(0, 0, 0, 0.3);
  }

  .title {
    text-align: center;
    margin-top: 30px;
  }

  .description,
  .category {
    text-align: center;
  }

  h5.description {
    max-width: 700px;
    margin: 20px auto 75px;
  }

  .nav-align-center {
    margin-top: 30px;
  }

  .content {
    .social-description {
      display: inline-block;
      max-width: 150px;
      width: 145px;
      text-align: center;
      margin: 15px 0 0px;

      h2 {
        margin-bottom: 15px;
      }
    }
  }

  .collections {
    img {
      margin-bottom: 30px;
    }
  }

  .gallery {
    margin-top: 45px;
    padding-bottom: 50px;
  }

  .card-coin {
    .list-group {
      margin: 0 !important;
      text-align: left !important;
      color: $opacity-5;
    }
  }
  .card {
    .card-header {
      .profile-title {
        top: 125px;
      }
    }
  }
}

.section-full-page {
  &:after,
  &:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &:before {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &[data-image]:after {
    opacity: 0.5;
  }

  > .content,
  > .footer {
    position: relative;
    z-index: 4;
  }

  > .content {
    min-height: calc(100vh - 80px);
  }

  .full-page-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
  }

  .footer nav > ul a:not(.btn),
  .footer,
  .footer .copyright a {
    color: $white;
  }
}

.register-page {
  background-color: $background-black;

  .link {
    font-size: 10px;
    color: $white;
    text-decoration: none;
  }

  .register-bg {
    height: 700px;
    background: linear-gradient(
      to bottom,
      $transparent-bg 0%,
      $card-black-background 100%
    );
    width: 700px;
    position: relative;
    right: -600px;
    top: -750px;
    border-radius: 20%;
    transform: rotate(-15deg);
  }

  .square {
    position: absolute;
    transition: 0.5s ease-out;
    border-radius: 2px;
    background-size: 100%;
    border-radius: 20%;
  }

  .square-1 {
    height: 300px;
    width: 300px;
    background-image: url("/assets/img/square1.png");
    top: 175px;
    right: 150px;
    opacity: 0.8;
  }

  .square-2 {
    height: 120px;
    width: 120px;
    background-image: url("/assets/img/square2.png");
    opacity: 0.9;
    top: 310px;
    right: 390px;
  }

  .square-3 {
    width: 200px;
    height: 200px;
    background-image: url("/assets/img/square3.png");
    opacity: 0.3;
    top: -50px;
    right: 400px;
  }

  .square-4 {
    height: 200px;
    width: 200px;
    background-image: url("/assets/img/square4.png");
    right: -50px;
    top: 525px;
  }

  .square-5 {
    height: 150px;
    width: 150px;
    background-image: url("/assets/img/square5.png");
    opacity: 0.5;
    top: 500px;
    right: 470px;
  }

  .square-6 {
    height: 70px;
    width: 70px;
    background-image: url("/assets/img/square5.png");
    opacity: 0.9;
    top: 105px;
    right: 20px;
  }

  .square-7 {
    height: 200px;
    width: 200px;
    background-image: url("/assets/img/square5.png");
    left: -100px;
    top: 100px;
    opacity: 0.4;
  }

  .square-8 {
    height: 100px;
    width: 100px;
    background-image: url("/assets/img/square1.png");
    left: 300px;
    top: 400px;
    opacity: 0.4;
  }
}

// .index-page,
// .landing-page,
// .profile-page,
// .register-page {
//   background-image: url("/assets/img/dots2.png");
//   background-size: contain;
// }

@include media-breakpoint-down(md) {
  .section:nth-of-type(2) {
    .path {
      left: 10% !important;
    }
    .path2 {
      left: 20% !important;
    }
  }

  .info:first-of-type {
    padding-top: 10% !important;
  }
}

@include media-breakpoint-down(sm) {
  .card-login img {
    width: 70%;
  }

  .section:nth-of-type(2) {
    .path,
    .path2 {
      left: 0 !important;
    }
  }
  .section-coins {
    margin-top: 0 !important;
  }
}

@include media-breakpoint-down(xs) {
  .card-login {
    margin-top: 100px;
    img {
      width: 350px;
    }
  }

  .profile-page .page-header {
    max-height: unset;
  }

  .landing-page {
    .page-header {
      h1 {
        margin-top: 100px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .info:first-of-type {
    padding-top: 60%;
  }
}


// --------------------
.background-blur {
      height: 100vh;
    min-height: 100vh;
    max-height: 999px;
    padding: 0;
    position: relative;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.3);
    /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
    backdrop-filter: blur(2.5px);
    -webkit-backdrop-filter: blur(2.5px);
    // border-radius: 10px;
    /* border: 1px solid rgba(255, 255, 255, 0.18); */
    z-index: 1;
    
}

//header
.header-intro {
  // font-family: 'Overpass', sans-serif;

  // font-family: 'Berkshire Swash', serif;

  font-family: 'Protest Strike', sans-serif;
  font-size: 70px;
  font-weight: 600;
  color: #303030;
  line-height: 72px;

  // & span {
  //   &.one {
  //   color: #126191f0;
  //   }
  //   &.two {
  //     color: #129190f0;
  //   }
  //   &.three {
  //     color: #91125cf0;
  //   }
  // }
}


.header-name {
  font-family: 'Overpass', sans-serif;
  // font-size: 20px;
  // font-weight: 400;
  // color: #516b68;
  // line-height: 60px;

  span {
    background-color: #f5f5dcb9 !important;

    @media only screen and (max-width: 600px) {
      line-height: 36px;
    }
  }

  // font-family: 'Protest Strike', sans-serif;
  font-size: 26px;
  font-weight: 500;
  color: #303030;
  line-height: 20px;
  padding-bottom: 15px;
}

.app-custom-link {
  cursor: pointer !important;
}

.navbar-items {
  a {
    font-family: 'Karla', sans-serif;
    p{
      color:  #303030;
      font-weight: 500;


      &::before {
        // transform: translateX(-50%);
        // position: absolute;
        // background: #00f2c342;
        // bottom: 12px;
        // height: 10px;
        content: "";
        
        background: #fde560bf;
        border-radius: 50%;
        width: 7px;
        height: 7px;
        position: absolute;
        left: 0%;
        bottom: -10%;
        display: none;
      }

      
      &::after {
        // transform: translateX(-50%);
        // position: absolute;
        // background: #00f2c342;
        // bottom: 12px;
        // height: 10px;
        content: "";
        
        background: #fff09abf;
        border-radius: 50%;
        width: 7px;
        height: 7px;
        position: absolute;
        left: 15%;
        bottom: -10%;
        display: none;
      }


      &:hover {
        &::before,
        &::after {
          display: block;
        }
      }
    }
  }
}

// section boundary

.story-lineup, .prof-row, .tec-story, .tec-stack, .my-companies, .testimo {
  padding: 50px;
}

.my-companies {
  &.works-title {
    @media only screen and (max-width: 600px) {
      padding-top: 0;
    }

  }
  .row {
    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
    }
    @media only screen and (max-width: 768px) {
      margin: 20px 0 !important;
  
      // .prof-comTitle .circles {
      //   display: none;
      // }
  
      &.my-work {
        flex-direction: row;
        .col-title {
          flex: 0 0 100% !important;
          max-width: 100% !important;
        }
        .col-3 {
          flex: 0 0 50%;
          max-width: 50%;
      }
      }
    }
  }
}

.testimo .row {
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.tec-stack .row {
  @media screen and (max-width: 768px) {
    flex-direction: row;
    padding: 0;

    .col-6 {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0;
      margin-bottom: 50px;
      .section-title {
        margin-bottom: 20px;
      }
    }

    .tec-process {
      img {
        padding: 0 !important;
      }
    }
  }
}

.tec-story {
  .tech-mobile {
    padding: 20px;
    // background: linear-gradient( to right, #ffdb1738 0%, #ffdb1738 70%, #eed65614 30%, #eed65614 100% );
    @media screen and (max-width: 768px) {
      .tec-row:nth-child(2) {
        margin-top: -35px;
        background: none;
      }
    }
  }
}

.section-title {
  font-family: 'Protest Strike', sans-serif;
  font-size: 50px;
  font-weight: 500;
  color: #303030 !important;
  line-height: 60px;
  padding: 15px 0px;

  @media screen and (max-width: 768px) {
    margin-bottom: 50px;

    .sir-name {
      font-size: 40px;
      line-height: 35px;
    }
  }

  &.full-width {
    padding: 0 !important;
    margin-bottom: 75px;
  }
}

// form page
.port-form {
  justify-content: flex-end;
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    padding-top: 10vh;
  }
}
.port-card {
  background: white;
  margin: 20px 0px;
  border-radius: 2px;
  min-width: 600px;
  border: 1px solid silver;
  border-radius: 8px;
  height: fit-content;
  height: -webkit-fill-available;

  @media screen and (max-width: 768px) {
    min-width: initial;
  }

  form {
    input {
      border: none;
    }
  }
}


.port-contact {
  color: black;
  text-align: left;
  font-family: 'Overpass', sans-serif;
  padding: 20px 80px;

  @media screen and (max-width: 768px) {
    padding: 20px;
  }

  &--header {
    font-size: 24px;
  }
  &--text {
    padding-bottom: 25px;
  }
  &--title {
    font-weight: 700;
  }
  &--contact {
    padding-bottom: 24px;
  }
}

.input-group {
  background: #f1f1f1;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 2px;
  input, textarea {
    font-family: 'Overpass', sans-serif;
  }
  &:hover, .input-group-focus {
    background-color: none;
    border: 1px solid rgb(187, 187, 187);
  }
  .form-control {
    color: black;
  }
}

.card-body {
  .form-inline {
    display: flex;
    flex-flow: inherit;
    gap: 10px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 0px;
      align-items: flex-start;
    }

    .input-group {
      width: 100%;
    }
  }
}
.card-btn {
  border: 1px solid black;
    background: none;
    color: black !important;
    font-family: 'Overpass', sans-serif;
    &:hover {
      color: black;
    }
}

.card-footer {
  text-align: right;
}



.wrapper {
  &.gradient-bg {
    // background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12);
    background-color: white;
  }
  .page-topper {
    padding-top: 10vh;
  }
}


// 
// profile page 
.tec-row {
  margin: 0 50px;

  @media screen and (max-width: 768px) {
    margin: 0;
    flex-direction: column;
  }
  .extend-top {
    padding-top: 70px;
    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }
  .extend-top-light {
    padding-top: 40px;
    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }
  .extend-top-lighter {
    padding-top: 20px;
    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }
  span {
    background-color: #eeeeee;
    z-index: 1;
    position: sticky;
  }
  .arr-blur {
    mask-image: linear-gradient(to right, transparent -50%, black 100%, black 0%, transparent 0%);
    -webkit-mask-image: linear-gradient(to right, transparent -50%, black 100%, black 0%, transparent 0%);
  }

  img {
    max-width: 100px;
    display: block;
    margin: auto;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .tec-subject {
    &--top {
      pointer-events: none;
      text-align: center;
      bottom: -20px;
      position: relative;
      font-family: 'Overpass', sans-serif;
      font-size: 14px;
      font-weight: 500;
      @media screen and (max-width: 768px) {
        text-align: start;
      }
    }
    &--bottom {
      pointer-events: none;
      text-align: center;
      top: -15px;
      position: relative;
      font-family: 'Overpass', sans-serif;
      font-size: 14px;
      font-weight: 500;
      @media screen and (max-width: 768px) {
        text-align: start;
      }
    }
  }
  .tec-arr1 {
    position: absolute;
    top: -60px;
    right: 0;
  }
  .tec-arr2 {
    position: absolute;
    top: -100px;
    right: 0;
    transform: scale(1, -1);
  }
  .tec-arr3 {
    position: absolute;
    top: -70px;
    right: -45px;
  }
  .tec-arr4 {
    position: absolute;
    right: -45px;
    top: 0;
    bottom: 0;
  }
  .tec-arr5 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -25px;
  }
 }

$grid-color: hsl(0, 0%, 95%);
.container.tec-stack {
  // margin: 100px;
  max-width: unset;
  // padding: 50px !important;
  // margin: 80px 0;

  background-color: rgba(#fff, .5);
	background-image: linear-gradient($grid-color 2px, transparent 2px), linear-gradient(90deg, $grid-color 2px, transparent 2px), linear-gradient(rgba(150, 150, 150, .3) 1px, transparent 1px), linear-gradient(90deg, rgba(150, 150, 150, .3) 1px, transparent 1px);
	background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
	background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
  background-attachment: fixed;
  background-position: center;
}
.prof-row {
  // margin: 50px;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    width: 100%;
    margin: 0;
  }
  .prof-photo, .prof-info {

  }
  .prof-photo {
      width: 40%;

      @media screen and (max-width: 768px) {
        width: auto;
      }

      img {
        height: 540px;
        margin-top: 35px;
        width: initial;
        object-fit: cover;
        border-radius: 0.2857rem !important;

        @media screen and (max-width: 768px) {
          width: 100%;
          height: 500px;
        }
      }
  }
  .prof-info {
      width: 60%;
      padding-right: 150px;

      @media screen and (max-width: 768px) {
        width: auto;
        padding: 0;

        .section-title {
          margin-bottom: 0px;
        }
      }

        .prof-title {
          padding: 5px;
          color: black;
          font-size: 24px;
          font-weight: 400;
          font-family: 'Overpass', sans-serif;
          &--sub {
            padding: 5px;
            color: black;
            font-size: 18px;
            font-weight: 400;
            font-family: 'Overpass', sans-serif;

          }
          span {
            text-decoration-color: #2adec529;
            text-decoration-thickness: 10px;
            text-decoration-line: line-through;
          }
        }
        .prof-description {
          padding: 5px;
          color: black;
          font-size: 16px;
          font-family: 'Overpass', sans-serif;
          
          ul li {
            color: black;
            font-size: 16px;
            font-weight: 300;
            list-style-type: none;
            &::before {
              content: "";
              border-top: 15px solid #ffe451;
              width: 1rem;
              margin: 0 8px;
              transform: translateY(2px);
              position: absolute;
              left: 0;
              z-index: -1;
            }
        

            img {
              height: 16px;
              opacity: 50%;
            }
          }

      }
  }
  .prof-tec1, .prof-tec2 {
  }
  .prof-tecTitle {
    width: 40%;
    padding: 0 20px;

    @media screen and (max-width: 768px) {
      width: auto;
    }

    span {
      background-color: #eeeeeec7
    }

    .title-head {
      padding: 5px;
      color: black;
      font-size: 24px;
      font-family: 'Overpass', sans-serif;
    }
    

  }

  .title-tail {
    padding-bottom: 15px;
    color: black;
    font-size: 16px;
    font-weight: 300;
    font-family: 'Overpass', sans-serif;

    @media screen and (max-width: 768px) {
      padding: 15px;
      text-align: center;
    }
  }

  .prof-tecImg {
    width: 60%;
    padding: 0 20px;

    &--prior {
      // background: #f2f2f29c;
      padding-top: 5px;
      margin-bottom: 20px;

      img {
        filter: 
        drop-shadow(2px 2px 0 #fde560bf)
        drop-shadow(-2px 2px 0 #fde560bf)
        drop-shadow(2px -2px 0 #fde560bf)
        drop-shadow(-2px -2px 0 #fde560bf);
      }

      .prof-tecName span {
        background-color: #ffdb17bf !important;
      }

      &:first-child {
        &::before {
          // content:url("/assets/img/cb-left.png");
          // position:relative;
          // z-index:100000;
          // left:-50px;
          // top:10px;

          // background-image: url("/assets/img/cb-left.png");
          // background-size: 10px 20px;
          // display: inline-block;
          // width: 10px; 
          // height: 20px;
          // content:"";
        }
      }
    }

    @media screen and (max-width: 768px) {
      width: auto;
      padding: 20px 10px;
      flex-direction: row;
      
      .col-md-2 {
        flex: 0 0 50%;
        max-width: 50%;

        height: 150px;
        margin-bottom: 60px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;

        img {
          width: 100px;
          height: 100px;
        }
      }
    }

    .col-md-2 {
      height: 100px;
    }
    img {
      // width: 100px;
      padding: 1px;
    }
    .prof-tecName {
      text-align: center;
      font-family: 'Overpass', sans-serif;
      font-size: 12px;
      span {
        background-color: #eeeeeec7
      }
    }

    .tecHr {
      width: 65%;
      margin: 10px;
      background-color: #fde5603d;
      height: 5px;
      border: 0;

      @media screen and (max-width: 768px) {
        width: 95%;
        margin-top: -15px;
        margin-bottom: 30px;
      }
    }

  }

  .col-icon {
    flex: 0 0 12%;
    max-width: 12%;
    text-align: center;

    @media screen and (max-width: 768px) {
      flex: 0 0 20%;
      max-width: 20%;
    }

    .tech-icon {
      max-width: 46px;
      margin: 10px;
    }
  }
}

.prof-comList {
  width: 60%;
  padding: 0 20px;

  @media screen and (max-width: 768px) {
    width: 100%;

    .row {
      flex-direction: row-reverse;
      
      .project-stake {
        flex: 0 0 45%;
        max-width: 45%;
      }
    }
  }

  
}

.prof-comTitle {
  width: 40%;
  padding: 0 20px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.prof-teTitle {
  width: 40%;
  padding: 0 20px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.prof-teTestimo {
  width: 60%;
  padding: 0 20px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.prof-col-5 {
  flex: 0 0 20%;
  max-width: 20%;

  position: relative;
  width: 100%;
  min-height: 200px;
  padding-right: 15px;
  padding-left: 15px;

  // border: 1px solid red;

  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;

    min-height: unset;
    padding: 0;
  }
   
}

.prof-inderline {
  text-decoration: underline;
    text-decoration-style: dashed;
    text-underline-offset: 4px;
    text-decoration-color: #dddddd;

    &:hover {
      text-decoration-color: #ffe451;
      cursor: pointer;
    }
}

.item-zoom {
  transition: 0.3s ease-out;

  &:hover {
    transform: scale(1.5);
    transition: 0.3s ease-out;
  }

}

.project-mb {
  margin-bottom: 120px;
  }
  @media only screen and (max-width: 600px) {
    .col-12.project-mb {
      .row {
        flex-direction: column;
        .col-6 {
          max-width: 100%;
          &.project-img {
            margin-bottom: -100px;
            .project-box {
              background-size: 100%;
              background-repeat: no-repeat;
            }
          }
          &.project-box {
            max-width: 100%;
            .project-context {
              padding: 25px 0;
            }
          }
        }
      }
    }
  }

.project-box {
  min-height: 310px;
  background-size: cover;
  transition: ease-in-out 0.5s;
  cursor: default;
  // &:hover {
  //   background-image: linear-gradient( rgb(0 0 0 / 0%), rgba(0, 0, 0, 0.7) ), url(/assets/img/cover-back.jpg);
  // }
  .project-context {
    // bottom: 0;
    // position: absolute;
    padding: 25px;
    .project-title, .sub-title, .desc {
      font-family: 'Overpass', sans-serif;
      color: black;
      span {
        // background-color: #eeeeeec7;
        &.sub-desc {
          background-color: #eeeeee8a;
          padding: 0 2px;
          font-size: 22px;
          font-weight: 200;
        }
      }

    }

    .project-title {
      font-size: 22px;
      color: #303030;
      font-weight: 500;
      padding-bottom: 8px;
      span {
        padding: 0 4px;
      }
    }
    .sub-title {
      font-size: 16px;
      padding-bottom: 5px;
    }
    .desc {
      font-size: 14px;
    }

    .projecy-tag {
      display: flex;
      height: 2px;
      width: 100px;
      border-radius: 25px;
      margin: 20px 0;
      background: #434343;
      &.dev {
        background: #35cbb9ad !important
      }
    }

    .view-project {
      font-family: 'Overpass', sans-serif;
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #434343;
      gap: 5px;
      padding: 30px 0;
      cursor: pointer;

      .view-icon {
        height: 24px;
        transition: 0.2s ease-in;
        filter: invert(22%) sepia(0%) saturate(2%) hue-rotate(166deg) brightness(100%) contrast(85%);
      }

      &:hover {
        .view-icon {
          transform: rotate(45deg);
          transition: 0.2s ease-in;
        }
      }
    }
  }
}

// infoins 
.box-1 {
  background-image: url(/assets/img/infoins-profile.png) !important;
  transition: opacity .2s;
}

// lsf 
.box-2 {
  background-image: url(/assets/img/lsf-profile.png) !important;
  transition: opacity .2s;
}

// optimo
.box-3 {
  background-image: url(/assets/img/wm-profile.png) !important;
}

// pocket libb
.box-4 {
  background-image: url(/assets/img/pl-profile.png) !important;
}

.box-5 {
  background-image: linear-gradient( rgb(0 0 0 / 0%), rgba(0, 0, 0, 0.7) ), url(/assets/img/cover-front-5.png) !important;
}

.title-head {
  padding: 50px 20px;
  margin-top: 35px;
  color: #303030;
    font-size: 24px;
    font-weight: 500;
  font-family: 'Overpass', sans-serif;
  // text-decoration: underline;
  text-align: center;
  span {
    // background: #fde560bf;
    padding: 2px 4px 0px 4px;
  }
}

.project-stake {
  background: #f7f9fa;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  display: flex;
  align-self: center;
  justify-content: center;
  margin: 2px;
  padding: 4px;

  img {
    // height: 65px;
    margin: 2px;
  }
}

.row:has(.project-stake) {
  justify-content: center;
}



// blog post 
.title-head-article {
  // padding: 20px;
  height: 400px;
  font-size: 24px;
  font-family: "Overpass", sans-serif;
  display: flex;
  flex-direction: column;
  place-content: end;

  img {
    height: 285px;
    border-radius: 4px;
    -o-object-fit: cover;
    object-fit: cover;

    @media only screen and (max-width: 600px) {
      height: 250px;
    }
    
  }

  &.title-bg {
    height: 250px;
    margin-top: 100px;
  }

  .article-header {
    position: absolute;
    padding: 15px;
    // max-width: 1070px;
    .article-title, .article-subtitle {
      color: #303030;
      span {
        background-color: #eeeeee;
        padding: 0 5px;

        @media screen and (max-width: 768px) {
          background-color: unset;
        }
      }
    }
    
    .article-subtitle {
      font-size: 18px;
      color: #303030;
    }
    .tags {
      display: flex;
      gap: 4px;
      margin-top: 10px;
      .article-tag {
        background-color: #ffdb17;
        padding: 3px 10px;
        border-radius: 25px;
        width: fit-content;
        width: -moz-fit-content;
        font-size: 10px;
        font-weight: 500;

        // &--tec {
        //   color: #ffdb17;
        //   background-color: #303030;
        // }
      }
    }
    

    @media screen and (max-width: 768px) {
      position: relative;
      padding: 10px 10px;
      max-width: 1070px;
      background: #ffdb17;
    }
  }
}

.col-article-side, .col-article-middle,
.col-article-side-2, .col-article-middle-2 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;   
    font-family: "Karla", sans-serif;
}

.col-article-side {
   flex: 0 0 20%;
   max-width: 20%;

   @media screen and (max-width: 768px) {
    flex: 0 0 0%;
    max-width: 0%;
   }
}
.col-article-side-2 {
  flex: 0 0 10%;
  max-width: 10%;

  @media screen and (max-width: 768px) {
   flex: 0 0 0%;
   max-width: 0%;
  }
}


.col-article-middle {
  flex: 0 0 80%;
  max-width: 80%;

  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 35px;
    padding-left: 35px;
  }
}
.col-article-middle-2 {
  flex: 0 0 80%;
  max-width: 80%;

  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 35px;
    padding-left: 35px;
  }
}



.context {
  font-size: 16px;
  .header {
    font-size: 14px;
    padding: 15px;
    background: #ededec8c;
    border-radius: 0 0 4px 4px;
    margin-bottom: 25px;
    .header-title {
      font-family: 'Protest Strike', sans-serif;
      font-size: 35px;
      @media only screen and (max-width: 600px) {
        margin-bottom: 15px;
      }
    }
    .row.sub-details-row {
      margin: 5px !important;
      gap: 10px;
      @media only screen and (max-width: 600px) {
        margin: 0px !important;
        gap: 2px;
      }
    }
    .sub-details {
      border: 1px solid #ececec;
      // box-shadow: 2px 2px 10px -4px #d2d2d2 inset;
      border-radius: 4px;
      padding: 10px !important;
      background: #fff;
      flex: 0 0 24% !important;
      max-width: 24% !important;

      @media only screen and (max-width: 600px) {
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }
    }
    span {
      // padding: 0 4px;
      margin-right: 4px;
    }
    .context-about {
      font-weight: 600;
    }
  }
  .prob-sol {
    padding: 50px 0;
    &--title {
      color: #098d8d; 
      font-size: 28px; 
      padding-bottom: 5px;
    }
    &--content {
      color: #098d8d;
    }

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      gap: 40px;
      padding: 24px 0;

      .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  .sub-sec {
    padding: 50px 0;
    &--titletag {
      span {
        padding: 2px 5px; 
        background: #ffdb177a;
      }
    }
    &--title {
      font-size: 28px; 
      padding: 0;
    }

    @media only screen and (max-width: 600px) {
      flex-direction: column;

      .col-4, .col-8 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  span.txttag {
    padding: 0px 2px; 
    background: #e3a7ff4f;
  }
  .custom-set {
    font-size: 12px;
    &--left, &--right {
      border: 1px solid #d7d7d7; 
      border-radius: 4px ; 
      padding: 15px; 
      .title {
        color: inherit;
        font-size: 14px; 
        font-weight: 600;
      }
      .highlight {
        font-style: italic; 
        font-weight: 600
      }
      .question {
        font-weight: 600
      }
    }
    &--left {
      margin-right: 10px;
    }
  }
  .custom-set {
    &--quis {
      color: #098d8d; 
      font-size: 18px;
      .quis span {
        width: 20px;
        display: inline-block;
      }
    }
    .ab-number {
      background: #ffdb177a;
      width: 20px;
      display: block;
      height: 20px;
      text-align: center;
      border-radius: 10px;
      margin-bottom: 5px;
    } 
    .ab-row {
      margin: 0;
      .col-6 {
        padding: 5px; 
        .ab-text {
          background: #ffdb177a;
          padding: 2px 5px;
          border-radius: 10px;
        }
      }
    }   
    
    .ab-shadow {
      box-shadow: 0px 1px 2px #bbbbbb;
    }
  }
  .sub-header {
    font-size: 20px;
    padding: 20px 0 5px;
    text-decoration: underline;
  }
  .support-image {

    &.img-1-1 {
      // height: 300px;
      background-size: cover;
      margin-top: 20px;
    }
    
  }
  .caption {
    margin: 10px 0 30px 0;
    font-size: 12px;
    font-style: oblique;
    display: flex;
    justify-content: center;
  }

  .context-blur {
    -webkit-mask-image: linear-gradient(to bottom, white 0%, transparent 100%);
    mask-image: linear-gradient(to bottom, white 0%, transparent 100%);
    height: 100px;
  }

  .list {
    li {
      color: #525f7f;
    }
  }

  .grid-table {
    margin: 35px 0;
    th, td span {
      font-size: 12px;
      font-weight: 500;
    }
    td, th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }
  }

  &__othet {
    margin-bottom: 30px;
    .circles-parent {
      text-align:center; 
      .circle {
        background:#d7d7d7; 
        height: 8px;
        width: 8px;
        border-radius:50%; 
        display:inline-block; 
        margin:0 5px;

      }
    }
  }

  &__othet--single {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    background: #f3f3f3;
    margin-bottom: 24px;

    position: relative;
    transition: 0.3s;

    &:hover {
      cursor: pointer;
      box-shadow: 2px 2px #ebebeb;
      transform: translate(-4px, -4px);
    }
    
    .row {
      margin: 0;

      .image {
        // height: 120px;
        width: 200px;
        display: flex;
        object-fit: cover;
        padding: 0;
        img {
        object-fit: cover;
        border-radius: 4px 0 0 4px !important;
        }
      }
      .dics {
        padding: 15px;

      }
    } 

    .dics-header {
      font-weight: 500;
      font-size: 14px;
    }
    .sub-header {
      font-size: 11px;
      padding: 0 !important;
    }
    .read-more {
      font-size: 14px;
      padding-top: 10px;
      color: #126191b8;
    }
  }

.read-medium {
  border: 1px solid #eeeeee;
  text-align: center;
  padding: 15px;
  background: aliceblue;
  border-radius: 8px;
  margin-bottom: 25px;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    background: #e5f0fa;
  }

  img {
    max-height: 50px;
    background: white;
    padding: 10px;
    border-radius: 100px;
    margin-bottom: 10px;
  }
}

.story--italic {
  font-style: italic;
  color: #656565;
  font-size: 14px;
  background: linen;
}

.testimo {
  .col-md-6:first-child {
    .testimo--box {
      margin: 0 10px 0 60px;
    }
  }
  .col-md-6:nth-child(2) {
    .testimo--box {
      margin: 0 60px 0 10px;
    }
  }
  &--box {
    font-family: "Overpass", sans-serif;
    padding: 25px;
    border-radius: 4px;
    background: #f7f9fa;
    border: 1px solid #eaeaea;

    // &:first-child {
    //   margin: 0 10px 0 60px;
    // }
  }
  &--pic {
    img {
      height: 75px;
      border-radius: 50%;
    }
  }
  &--quote {
    img {
      height: 20px;
      opacity: 10%;    
    }
  }
  &--content {
    font-size: 14px;
    margin-top: 5px;

  }
  &--auther {
    font-size: 12px;
    margin-top: 5px;
    color: #8d8d8d;

  }
  &--job {
    font-size: 11px;
    text-decoration: wavy;
    color: #8d8d8d;
  }
}

.testimo--pic {
  padding-top: 15px;
}


// more information 

.moreinfo--tip {
  text-decoration:none;
  position:relative;
}
 
.moreinfo--tip span {
  display:none;
  -moz-border-radius:6px;
  -webkit-border-radius:6px;
  border-radius:6px;
  color:black;
  background:white; 
}
 
.moreinfo--tip span img {
  float:left;
  margin:0px 8px 8px 0;
}
 
.moreinfo--tip:hover span {
  display:block;
  position:absolute;
  top:0;
  left:0;
  z-index:1000;
  width:auto;
  max-width:320px;
  min-height:128px;
  border:1px solid black;
  margin-top:12px;
  margin-left:32px;
  overflow:hidden;
  padding:8px;
}

.desc-img {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}



}

.context__othet {
  .row {
    @media only screen and (max-width: 600px) {
      .col-4.read-other {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}

.social-icons {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  list-style-type: none;

  right: -150px;
  top: 25px;
  gap: 20px;

  li .fab {
    font-size: 22px;
    color: #303030 !important;
  }

  @media screen and (max-width: 768px) {
    top: initial;
    right: initial;
    left: -20px;
    bottom: -150px;
    flex-direction: row;

    li .fab {
      font-size: 28px;
    }

    .social-icons--li {
      p {
        display: none;
      }
    }
  }
}


// circle underline

.circles,
.circles:before,
.circles:after {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  content: '';
  display: block;
}

.circles {
  background-color: #fde560bf;
  position: absolute;

  &.set1 {
    right: 155px;
    @media screen and (max-width: 768px) {
      left: 55px;
    bottom: 20px;
    }
  }
  &.set2 {
    display: inline;
    margin-top: 45px;
    margin-left: 35px;
    @media screen and (max-width: 768px) {
      margin-top: 95px;
      left: 95px;
      margin-left: 0;
    }
  }
  &.set3 {
    display: inline;
    margin-top: 45px;
    margin-left: 40px;
    @media screen and (max-width: 768px) {
      left: 100px;
      margin-top: 100px;
      margin-left: 0;
    }
   }

   &.set4 {
    margin-top: 20px;
    margin-left: 25px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
   }
   &.set5 {
    margin-top: 20px;
    margin-left: 280px;
    @media screen and (max-width: 768px) {
      margin-left: 25px;
    }
  }
  &.set6 {
   margin-top: 20px;
   margin-left: 280px;
    @media screen and (max-width: 768px) {
      margin-left: 25px;
    }
  }
  &.setFooter {
    margin-top: 20px;
    margin-left: 330px;
    @media screen and (max-width: 768px) {
      margin-left: 25px;
    }
  }
}

.circles:before,
.circles:after {
  position: absolute;
}

.circles:before {
  left: -25px;
  background-color: #fff09abf;
}

.circles:after {
  left: 25px;
  background-color: #ffdb17bf;
}
