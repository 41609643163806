// Base class
.tooltip {
  position: absolute;
  z-index: $zindex-tooltip;
  display: block;
  margin: $tooltip-margin;
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include reset-text();
  font-size: $tooltip-font-size;
  // Allow breaking very long words so they don't overflow the tooltip's bounds
  word-wrap: break-word;
  opacity: 0;

  &.show {
    opacity: $tooltip-opacity;
  }

  .arrow {
    position: absolute;
    display: block;
    width: $tooltip-arrow-width;
    height: $tooltip-arrow-height;

    &::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
}

.bs-tooltip-top {
  padding: $tooltip-arrow-height 0;

  .arrow {
    bottom: 0;

    &::before {
      top: 0;
      border-width: $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
      border-top-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-right {
  padding: 0 $tooltip-arrow-height;

  .arrow {
    left: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      right: 0;
      border-width: ($tooltip-arrow-width / 2) $tooltip-arrow-height
        ($tooltip-arrow-width / 2) 0;
      border-right-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-bottom {
  padding: $tooltip-arrow-height 0;

  .arrow {
    top: 0;

    &::before {
      bottom: 0;
      border-width: 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
      border-bottom-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-left {
  padding: 0 $tooltip-arrow-height;

  .arrow {
    right: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      left: 0;
      border-width: ($tooltip-arrow-width / 2) 0 ($tooltip-arrow-width / 2)
        $tooltip-arrow-height;
      border-left-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-auto {
  &[x-placement^="top"] {
    @extend .bs-tooltip-top;
  }
  &[x-placement^="right"] {
    @extend .bs-tooltip-right;
  }
  &[x-placement^="bottom"] {
    @extend .bs-tooltip-bottom;
  }
  &[x-placement^="left"] {
    @extend .bs-tooltip-left;
  }
}

// fab for the tooltip content
.tooltip-inner {
  max-width: $tooltip-max-width;
  padding: $tooltip-padding-y $tooltip-padding-x;
  color: $tooltip-color;
  text-align: center;
  background-color: $tooltip-bg;
  @include border-radius($tooltip-border-radius);
}

// new tooltip 

.comp-mural {
  display: flex !important;
  flex-direction: row !important;
  width: max-content !important;

  img {
    height: 250px !important;
    width: auto;
    opacity: 100% !important;

    &:first-child {
      padding-right: 10px;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column !important;

    img:first-child {
      padding-right: 0px;
      padding-bottom: 10px;
    }
  }
} 
  
.fab .fab-tooltip,
.fab-companies .fab-tooltip {
    font-family: "Overpass", sans-serif;
    font-size: 14px;
  background: #ffdb17;
  color: #303030;
  display: block;
  left: -20px;
  margin-bottom: 5px;
  opacity: 0;
  padding: 10px;
  pointer-events: none;
  position: absolute;
  width: auto;
  border-radius: 2px;
  -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
     -o-transform: translateY(10px);
      transform: translateY(10px);
  -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -ms-transition: all .25s ease-out;
     -o-transition: all .25s ease-out;
      transition: all .25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.12);
    -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.12);
     -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.12);
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.12);

      &.medium { bottom: 100%; }
      &.linkedin { bottom: 100%; }
      &.behance { bottom: 38%; }

      &.comp-tooltip { bottom: 100%; }
  }
  
  /* This bridges the gap so you can mouse into the tooltip without it disappearing */
  // .fab .fab-tooltip:before {
  // bottom: -20px;
  // content: " ";
  // display: block;
  // height: 20px;
  // left: 0;
  // position: absolute;
  // width: 100%;
  // }
  
  /* CSS Triangles - see Trevor's post */
  .fab .fab-tooltip:after,
  .fab-companies .fab-tooltip:after {
    border-left: solid transparent 0px;
    border-right: solid transparent 10px;
    border-top: solid #ffdb17 10px;
    bottom: -10px;
    content: " ";
    height: 0;
    left: 50%;
    margin-left: 30px;
    position: absolute;
    width: 0;
  }

  .fab-companies .fab-tooltip.comp-mural:after {
    left: 15% !important;
  }
  
  .fab:hover .fab-tooltip,
  .fab-companies:hover .fab-tooltip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
     -o-transform: translateY(0px);
      transform: translateY(0px);
  }
  
  /* IE can just show/hide with no transition */
  .lte8 .fab .fab-tooltip,
  .lte8 .fab-companies .fab-tooltip {
  display: none;
  }
  
  .lte8 .fab:hover .fab-tooltip,
  .lte8 .fab-companies:hover .fab-tooltip {
  display: block;
  }