.footer {
  padding: 3.125rem 0;
  // border-top: .125rem solid #435db5;
  background: $bg-secondary;

  [class*="container-"] {
    padding: 0;
  }

  .footer-container {
    text-align: center;

    .footer-row {
      @media only screen and (max-width: 600px) {
        flex-direction: column;
        .footer-left, .footer-right {
          width: 100%;
          padding: 0 40px;
        }
        .footer-left .section-title {
          text-align: left;
          margin: 0;
          font-size: 40px;
        }
      }
    }

  }

  .col-title{
    display: grid;
    gap: 25px;
  }

  .footer {
    background-color: #303030 !important;
    
  }

  .footer-left {
    width: 40%;
    padding: 0 20px;

    .section-title {
      text-align: right;
      color: #ffba03 !important;

      .footer-coffee {
        height: 125px;
      }
    }
  }

  .footer-right {
    width: 60%;
    padding: 30px;
    text-align: left;

    @media screen and (max-width: 768px) {
      padding-left: 60px;
    }

    .footer-p {
      padding-bottom: 20px;

      div {
        padding-top: 20px;
      }
    }
  }

  .footer-title {
    font-family: "Overpass", sans-serif;
    font-size: 28px;
    font-weight: 600;
    color: #0b0f4b;
    line-height: 45px;
  }
  
  .footer-p {
    font-family: "Overpass", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #d7d7d7;
    // line-height: 60px;
    & span {
      &.one,&.two ,&.three {
      color: #ffdb17bf !important;
      }
      // &.two {
      //   color: #129190f0;
      // }
      // &.three {
      //   color: #91125cf0;
      // }
    }
  }

  .footer-social {
    img {
      &:first-child {
        padding: 5px;
        border: 2px solid #ffffff5c;
        width: 40px;
        margin-right: 15px;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);

        &:hover {
          filter: none;
        }
      }
      &:nth-child(2) {
        width: 54px;
      }
    }

  }

  .col-copyright {
    font-size: 10px;
    font-family: "Overpass", sans-serif;
    text-align: center;
    color: $active-gray;
    margin-top: 50px;
    img {
      width: 20px;
      vertical-align: initial;
      border-radius: unset;
    }
  }

  // .nav {
  //   display: block;
  //   float: left;
  //   margin-bottom: 0;
  //   padding-top: 1.5625rem;
  //   list-style: none;
  // }

  // .nav-item {
  //   display: block;
  // }

  // .nav-link {
  //   color: $white;
  //   padding: .625rem 0;
  //   font-size: $font-size-sm;
  //   text-transform: uppercase;
  //   text-decoration: none;

  //   &:hover {
  //     text-decoration: none;
  //   }
  // }

  // .title {
  //   text-align: left !important;
  // }

  // .copyright {
  //   font-size: $font-size-sm;
  //   line-height: 1.8;
  //   color: $white;
  // }

  &:after {
    display: table;
    clear: both;
    content: " ";
  }
}

@media screen and (max-width: 61.9375rem) {
  .footer {
    padding-left: 0rem;

    .copyright {
      text-align: right;
      margin-right: .9375rem;
    }
  }
}

@media screen and (min-width: 62rem) {
  .footer {
    .copyright {
      float: right;
      padding-right: 1.875rem;
    }
  }
}

@media screen and (max-width: 48rem) {
  .footer {
    nav {
      display: block;
      margin-bottom: .3125rem;
      float: none;
    }
  }
}

@media screen and (max-width: 36rem) {
  .footer {
    text-align: center;
    .copyright {
      text-align: center;
    }

    .nav {
      float: none;
      padding-left: 0;
    }
  }
}
