@font-face {
  font-family: ledPanel;
  src: url(/../../../assets/font/ledPanel.ttf) format("opentype");

  @font-face {
    font-family: 'ledPanel';
    src: url('/assets/font/ledPanel.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
}


.index-page .squares {
  // @include linear-gradient($danger-states, $danger);
  // background: #adadad;
}
.register-page,
.section.section-signup {
  .squares,
  .square {
    @include linear-gradient($danger-states, $danger);
  }
}
// .footer {
//   border-top: 2px solid $danger;
// }

//misc
.content-slider {
  width: 100%;
  height: 150px;
}

.slider {
  // height: 100px;
  width: 680px;
  margin: 5px auto 0;
  overflow: visible;
  position: relative;
  display: inline-block;
  align-self: flex-end;
}

.mask {
  overflow: hidden;
  height: 33px;
}

.slider ul {
  margin: 0;
  padding: 0;
  position: relative;
}

.slider li {
  // width: 680px;
  height: 150px;
  position: absolute;
  top: -65px;
  list-style: none;
}

.slider .quote {
  font-size: 22px;
  letter-spacing: -2px;
  // font-style: italic;
  // font-family: 'Edu NSW ACT Foundation', cursive;

  box-sizing: border-box;
  margin: 0;
  padding: 0;
  // font-family: 'ledPanel';
  font-family: 'Silkscreen', sans-serif;

  // color: #b4b4b4;
  color: #ececec;
  background: black;
  // padding: 0 10px;
  &.start {
    padding-left: 5px;
  }
  &.end {
    padding-right: 5px;
  }
}

.slider li.anim1 {
  animation: cycle 15s linear infinite;
}

.slider li.anim2 {
  animation: cycle2 15s linear infinite;
}

.slider li.anim3 {
  animation: cycle3 15s linear infinite;
}

.slider li.anim4 {
  animation: cycle4 15s linear infinite;
}

.slider li.anim5 {
  animation: cycle5 15s linear infinite;
}

.slider:hover li {
  animation-play-state: paused;
}

@keyframes cycle {
  0% {
    top: 0px;
  }
  4% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  16% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  20% {
    top: 64px;
    opacity: 0;
    z-index: 0;
  }
  21% {
    top: -64px;
    opacity: 0;
    z-index: -1;
  }
  50% {
    top: -64px;
    opacity: 0;
    z-index: -1;
  }
  92% {
    top: -64px;
    opacity: 0;
    z-index: 0;
  }
  96% {
    top: -64px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}

@keyframes cycle2 {
  0% {
    top: -64px;
    opacity: 0;
  }
  16% {
    top: -64px;
    opacity: 0;
  }
  20% {
    top: 0px;
    opacity: 1;
  }
  24% {
    top: 0px;
    opacity: 1;
  }
  36% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  40% {
    top: 64px;
    opacity: 0;
    z-index: 0;
  }
  41% {
    top: -64px;
    opacity: 0;
    z-index: -1;
  }
  100% {
    top: -64px;
    opacity: 0;
    z-index: -1;
  }
}

@keyframes cycle3 {
  0% {
    top: -64px;
    opacity: 0;
  }
  36% {
    top: -64px;
    opacity: 0;
  }
  40% {
    top: 0px;
    opacity: 1;
  }
  44% {
    top: 0px;
    opacity: 1;
  }
  56% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  60% {
    top: 64px;
    opacity: 0;
    z-index: 0;
  }
  61% {
    top: -64px;
    opacity: 0;
    z-index: -1;
  }
  100% {
    top: -64px;
    opacity: 0;
    z-index: -1;
  }
}

@keyframes cycle4 {
  0% {
    top: -64px;
    opacity: 0;
  }
  56% {
    top: -64px;
    opacity: 0;
  }
  60% {
    top: 0px;
    opacity: 1;
  }
  64% {
    top: 0px;
    opacity: 1;
  }
  76% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  80% {
    top: 64px;
    opacity: 0;
    z-index: 0;
  }
  81% {
    top: -64px;
    opacity: 0;
    z-index: -1;
  }
  100% {
    top: -64px;
    opacity: 0;
    z-index: -1;
  }
}

@keyframes cycle5 {
  0% {
    top: -64px;
    opacity: 0;
  }
  76% {
    top: -64px;
    opacity: 0;
  }
  80% {
    top: 0px;
    opacity: 1;
  }
  84% {
    top: 0px;
    opacity: 1;
  }
  96% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }
  100% {
    top: 65px;
    opacity: 0;
    z-index: 0;
  }
}

.highlight-yellow {
  // border-radius: 1em 0 1em 0;
  // background-image: linear-gradient(
  //   -100deg,
  //   rgba(255, 224, 0, 0.2),
  //   rgba(255, 224, 0, 0.7) 95%,
  //   rgba(255, 224, 0, 0.1)
  // );

  // animation: glow 2s ease-in-out infinite alternate;
  // text-shadow: 0px 0px 10px #fff, 1px 1px 0px #ccc;
  text-shadow: 0px 0px 1px #bbff22d1, 0px 0px 4px #fff112;
}

// @keyframes glow {
//   from{
//   text-shadow: 0 0 10px rgb(255, 247, 161), 0 0 20px rgb(251, 255, 146), 0 0 30px rgb(242, 255, 170), 0 0 40px rgb(252, 255, 177),0 0 50px rgb(246, 255, 114);
// }
// to {
//   text-shadow: 0 0 10px rgb(255, 247, 161), 0 0 20px rgb(251, 255, 146), 0 0 30px rgb(242, 255, 170), 0 0 40px rgb(252, 255, 177),0 0 50px rgb(246, 255, 114);
// }
// }